<template>
  <v-row justify="center">
    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filtered" scrollable persistent width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-1 text-first d-flex mb-4"
          style="background-color: #652065; height: 50px"
        >
          <span class="white--text"> Form Filter </span>
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.filtered = false" color="white"
            >ri-close-line</v-icon
          >
        </v-card-title>
        <v-card-text style="height: 500px" class="py-5">
          <v-text-field
            v-model="filter.code"
            outlined
            dense
            class="mb-5"
            placeholder="Cari berdasarkan kode lisensi"
            hide-details="auto">
          </v-text-field>
          <v-select
            filled
            dense
            placeholder="Filter berdasarkan status digunakan"
            v-model="filter.is_used"
            :items="is_used"
            outlined>
            <template slot="item" slot-scope="{ item }">
              <div class="text-capitalize">{{ item.text }}</div>
            </template>
          </v-select>
          <v-select
            filled
            v-model="filter.is_sold"
            placeholder="Filter berdasarkan status pesanan"
            :items="is_sold"
            outlined>
            <template slot="item" slot-scope="{ item }">
              <div class="text-capitalize">{{ item.text }}</div>
            </template>
          </v-select>
          <v-select
            filled
            dense
            placeholder="Filter berdasarkan status pengiriman licensi"
            v-model="filter.is_sent"
            :items="is_sent"
            outlined>
            <template slot="item" slot-scope="{ item }">
              <div class="text-capitalize">{{ item.text }}</div>
            </template>
          </v-select>
          <v-menu
            ref="menu"
            v-model="dialog.picker_start"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filterSoldDate"
                filled
                outlined
                dense
                readonly
                label="Filter berdasarkan tanggal terjual"
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            
            <v-date-picker
              ref="picker"
              :max="new Date().toISOString().substr(0, 10)"
              v-model="filter.sold_date"
              @input="dialog.picker_start = false"
            >
            </v-date-picker>
          </v-menu>

          <!-- <v-menu
            ref="menu"
            v-model="dialog.picker_end"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filterEndDate"
                filled
                outlined
                dense
                readonly
                label="Filter Tanggal Akhir"
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            
            <v-date-picker
              ref="picker"
              :max="new Date().toISOString().substr(0, 10)"
              v-model="filter.end_date"
              @input="dialog.picker_end = false"
            >
            </v-date-picker>
          </v-menu> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            width="100"
            color="#652065"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="search()"
          >
            Filter
          </v-btn>
          <v-btn
            width="100"
            elevation="0"
            color="yellow darken-2"
            class="text-capitalize white--text"
            :disabled="process.run"
            @click="resetFilter()"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG HISTORY SENT -->
    <v-dialog v-model="dialog.history_sent" scrollable persistent max-width="700px">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 text-first d-flex mb-4" style="background-color: #652065;height:50px">
          <span class="white--text mb-2">
            History Kirim Licensi
          </span>
          <v-spacer></v-spacer>
          <v-icon size="25" class="mb-2" @click="dialog.history_sent = false" color="white">ri-close-line</v-icon>
        </v-card-title>
        <v-card-text style="height: 500px;" class="">
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">Pengirim</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">Status Kirim</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-for="(history, i) in detailHistorySend.history" :key="i">
            <v-list-item three-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>{{ history.user.name }}</v-list-item-title>
                <v-list-item-subtitle class="subtitle-2">
                  {{ history.user.email }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption mt-2">
                  Dikirim pada : <br> 
                  <div class="caption font-weight-bold">
                    {{ history.created_at | datetime }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle class="subtitle-2">
                  <v-chip
                    v-if="history.status == 'success'"
                    color="#E5F7D2"
                    label>
                    Licensi Terkirim
                  </v-chip>
                  <v-chip
                    v-else
                    color="red"
                    label>
                    Licensi Gagal dikirim
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              :key="i">
            </v-divider>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-capitalize white--text"
            @click="action(detail, 'resend')">
            Kirim Ulang Licensi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-col md="6">
      <v-card elevation="0" outlined>
        <v-col>
          <v-form ref="form" @submit.prevent="search">
            <v-row no-gutters class="flex-nowrap" align="start">
              <v-text-field
                v-model="code"
                class="mr-3"
                outlined
                filled
                placeholder="Masukkan kode lisensi"
                hide-details="auto"
                :rules="[(v) => !!v || 'Kode lisensi tidak boleh kosong']">
              </v-text-field>
              <v-btn
                color="primary"
                elevation="0"
                :loading="isSearching"
                type="submit">
                <span> Cari </span>
              </v-btn>
              <v-btn
                v-if="Object.keys(params).length"
                class="ml-2"
                outlined
                color="primary"
                elevation="0"
                @click="
                  $refs.form.reset();
                  $store.commit('license/SET_PARAMS', {});
                  fetchData();">
                <span> Reset </span>
              </v-btn>
            </v-row>
          </v-form>
        </v-col>
      </v-card>
    </v-col> -->
    <v-col cols="12">
      <v-row justify="end" class="mb-5">
        <v-col cols="12" md="5">
          <div class="d-flex">
            <v-text-field
              v-model="searching"
              outlined
              :disabled="process.run"
              dense
              prepend-inner-icon="ri-search-line"
              placeholder="Cari Email Pengguna . . ."
              hide-details="auto"
            >
              <template v-slot:append v-if="searching">
                <v-icon @click="searching = ''">ri-close-line</v-icon>
              </template>
            </v-text-field>
            <v-btn
              color="teal"
              small
              :disabled="process.run"
              class="white--text mx-3"
              depressed>
              <v-icon left>ri-file-excel-2-line</v-icon>
              <vue-excel-xlsx
                :data="listExcel"
                :columns="columnsExcel"
                :file-name="`Data Lisensi ${dateNow}`"
                :file-type="'xlsx'"
                :sheet-name="'Data Lisensi'">
                Export Excel
              </vue-excel-xlsx>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="dialog.filtered = true"
                  :loading="process.run"
                  dense
                  small
                  elevation="0"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon color="#652065">ri-filter-line</v-icon>
                </v-btn>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>

      <!-- FILTER DATA -->
      <div
        v-if="
          status.date ||
          status.code ||
            status.is_used ||
            status.is_sold ||
            status.is_sent
        "
      >
        Pencarian :
        <v-chip
          v-if="status.date"
          class="ma-2"
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          close
          @click:close="handleCloseChips('date')"
        >
          {{ filterSoldDate }}
        </v-chip>
        <v-chip
          v-if="status.code"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('code')"
          style="text-transform:capitalize;"
        >
          Kode Lisensi : {{ filter.code }}
        </v-chip>
        <v-chip
          v-if="status.is_used"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('is_used')"
          close-color="red"
          style="text-transform:capitalize;"
        >
          Status Lisensi : {{ filternames.is_used }}
        </v-chip>
        <v-chip
          v-if="status.is_sold"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('is_sold')"
        >
          Status Lisensi : {{ filternames.is_sold }}
        </v-chip>
        <v-chip
          v-if="status.is_sent"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('is_sent')"
        >
          Status Pengiriman Lisensi : {{ filternames.is_sent }}
        </v-chip>
      </div>

      <v-card elevation="0" outlined>
        <v-data-table
          :headers="headers"
          :items="data.list || []"
          :items-per-page.sync="data.limit"
          :page.sync="data.page"
          :server-items-length="data.total"
          :loading="dataLoading"
          hide-default-footer
          disable-filtering
          disable-sort
        >
          <template slot="item.code" slot-scope="{ item }">
            <div>{{ item.code }}</div>
            <br />
            <v-chip v-if="validity(item)" small>{{ validity(item) }}</v-chip>
          </template>
          <template slot="item.order_no" slot-scope="{ item }">
            <span>{{ item.member_order.order_no.replace("YEC", "PBUM") || "-" }}</span>
          </template>
          <template slot="item.buyer" slot-scope="{ item }">
            <span v-if="item.buyer.email">{{ item.buyer.email }}<br /></span>
            <span class="color-navy-soft" v-if="item.buyer.full_name"
              >{{ item.buyer.full_name }}<br
            /></span>
          </template>
          <template slot="item.user" slot-scope="{ item }">
            <span v-if="item.user.email">{{ item.user.email }}<br /></span>
            <span class="color-navy-soft" v-if="item.user.full_name"
              >{{ item.user.full_name }}<br
            /></span>
          </template>
          <template slot="item.is_sended" slot-scope="{ item }">
            <span class="text-center" v-if="item.sent_email">
              <v-chip
                v-if="item.sent_email.is_sent"
                @click="detailSenderEmail(item)"
                color="#E5F7D2"
                label>
                Terkirim
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" size="20" right>ri-history-line</v-icon>
                  </template>
                  <span>Lihat History Kirim Licensi</span>
                </v-tooltip>
              </v-chip>
              <v-chip
                v-else
                @click="detailSenderEmail(item)"
                color="#E5E9F2"
                label>
                Belum dikirim
              </v-chip>
            </span>
            <span v-else>-</span>
          </template>
          <template slot="item.status" slot-scope="{ item }">
            <span v-if="item.is_sold">
              <v-chip class="mb-2" outlined color="green">
                Dipesan pada: {{ item.sold_at | datetime }}
              </v-chip>
              &nbsp;
            </span>
            <span v-if="item.is_used">
              <v-chip class="mb-2" outlined color="blue">
                Digunakan pada: {{ item.user.used_at | datetime }}
              </v-chip>
            </span>
          </template>
          <template slot="item.action" slot-scope="{ item }">
            <div class="d-flex flex-nowrap">
              <!-- #BUTTON DETAIL -->
               <v-tooltip bottom>
                <v-btn
                  small
                  slot="activator"
                  slot-scope="{ on, attrs }"
                  v-on="on"
                  v-bind="attrs"
                  elevation="0"
                  color="primary"
                  class="mr-1"
                  icon
                  @click="detail_license(item)"
                >
                  <v-icon>ri-information-line</v-icon>
                </v-btn>
                <span>View Detail</span>
              </v-tooltip>

              <!-- #BUTTON BOOKING -->
              <!-- v-if="!item.is_sold" -->
              <v-tooltip bottom>
                <v-btn
                  :disabled="item.is_sold"
                  small
                  slot="activator"
                  slot-scope="{ on, attrs }"
                  v-on="on"
                  v-bind="attrs"
                  elevation="0"
                  color="primary"
                  class="mr-1"
                  icon
                  @click="action(item, 'book')"
                >
                  <v-icon>ri-user-add-line</v-icon>
                </v-btn>
                <span>Mark as sold</span>
              </v-tooltip>

              <!-- #BUTTON USED -->
              <!-- v-if="!item.is_used && false" -->
              <v-tooltip bottom>
                <v-btn
                  :disabled="item.is_used && true"
                  small
                  slot="activator"
                  slot-scope="{ on, attrs }"
                  v-on="on"
                  v-bind="attrs"
                  elevation="0"
                  color="primary"
                  class="mr-1"
                  icon
                  @click="action(item, 'used')"
                >
                  <v-icon>ri-user-received-2-line</v-icon>
                </v-btn>
                <span>Mark as used</span>
              </v-tooltip>

              <!-- #BUTTON DELETE -->
              <v-tooltip bottom>
                <v-btn
                  small
                  slot="activator"
                  slot-scope="{ on, attrs }"
                  v-on="on"
                  v-bind="attrs"
                  elevation="0"
                  color="primary"
                  class="mr-1"
                  icon
                  @click="action(item, 'delete')"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
          <template slot="footer" slot-scope="{ props }">
            <v-row class="px-4">
              <div class="pa-3">
                <v-row align="center">
                  <div>
                    <v-subheader v-text="'Row per page: '"></v-subheader>
                  </div>
                  <div>
                    <v-select
                      class="mt-0 pt-0"
                      style="width: 4rem"
                      v-model="data.limit"
                      :items="[10, 20, 50, 100]"
                      hide-details="auto"
                    ></v-select>
                  </div>
                </v-row>
              </div>
              <v-col>
                <v-row class="mx-3" justify="end" align="center">
                  <div class="d-flex flex-nowrap align-center">
                    <v-subheader class="mr-2">Halaman:</v-subheader>
                    <v-select
                      v-model="data.page"
                      dense
                      solo
                      class="pa-0 ma-0 mr-2"
                      hide-details
                      :items="
                        Array.from(
                          { length: Math.ceil(data.total / data.limit) },
                          (_, i) => i + 1
                        )
                      "
                      style="width: 5rem"
                    ></v-select>
                    <v-subheader>
                      Menampilkan data ke
                      {{ props.pagination.pageStart + 1 }} sampai
                      {{ props.pagination.pageStop }} dari
                      {{ props.pagination.itemsLength }}
                    </v-subheader>
                  </div>
                  <v-btn
                    icon
                    small
                    :disabled="data.page == 1"
                    @click="data.page--"
                  >
                    <v-icon>$prev</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    :disabled="data.page == Math.ceil(data.total / data.limit)"
                    @click="data.page++"
                  >
                    <v-icon>$next</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
        <v-row v-show="false">
          <v-col md="6" offset-md="3">
            <div class="title text-center">
              <v-img
                src="@/assets/images/data_empty.png"
                width="66.667%"
                class="mb-4 mx-auto"
              ></v-img>
              Lisensi tidak ditemukan
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog.detail_license" scrollable persistent transition="dialog-bottom-transition" width="75%">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-1 text-first d-flex mb-4"
          style="background-color: #652065; height: 50px">
          <span class="white--text"> Dialog Detail License {{ detail.code }} </span>
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.detail_license = false" color="white">ri-close-line</v-icon>
        </v-card-title>
        <v-card-text style="height:600px">

          <v-row v-if="Object.keys(detail).length > 0">
            <v-col cols="12" md="6">
              <!-- ==== INFORMASI PRODUK ==== -->
              <v-card flat>
                <div class="text-h6 font-weight-regular mb-5 text-second">
                  Informasi Buyer
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr>
                        <th class="text-second pl-0" width="30%">Courier</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.courier == '' ? '-' : detail.buyer.courier }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Courier Type</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.courier_type == '' ? '-' : detail.buyer.courier_type }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Buyer FullName</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.fullname == '' ? '-' : detail.buyer.fullname }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Buyer Email</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.email == '' ? '-' : detail.buyer.email }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Catatan Order</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.order_note == '' ? '-' : detail.buyer.order_note }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Penerima</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.receiver == '' ? '-' : detail.buyer.receiver }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Alamat Penerima</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.receiver_address == '' ? '-' : detail.buyer.receiver_address }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">No Telepon</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.receiver_phone == '' ? '-' : detail.buyer.receiver_phone }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Kode Pos</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.receiver_post_code == '' ? '-' : detail.buyer.receiver_post_code }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <!-- ==== RINGKASAN PERSEDIAAN ==== -->
              <v-card flat>
                <div class="text-h6 font-weight-regular mb-5 text-second">
                  Ringkasan Item Buyer
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr>
                        <th class="text-second pl-0" width="30%">Sertificate Type</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.is_fisik == '' ? '-' : detail.buyer.is_fisik }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Order ID</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.wp_order_id == '' ? '-' : detail.buyer.wp_order_id }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Name</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.name == '' ? '-' : detail.buyer.item.name }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Height</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.height | num_format }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Lenght</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.length | num_format }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Quantity</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.quantity | num_format }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Value</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.value | num_format }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Weight</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.weight | num_format }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Width</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.width | num_format }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalBooking" width="500" scrollable>
      <v-form ref="bookingForm" @submit.prevent="booking">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Booking lisensi</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 500px">
            <v-row>
              <v-col cols="12">
                <span>Nama Lengkap</span>
                <v-text-field
                  v-model="bookingData.fullname"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Email</span>
                <v-text-field
                  v-model="bookingData.email"
                  type="email"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Order ID</span>
                <v-text-field
                  v-model="bookingData.wp_order_id"
                  type="number"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required, rules.wp_order_id]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Courier</span>
                <v-text-field
                  v-model="bookingData.courier"
                  type="text"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Courier Type</span>
                <v-text-field
                  v-model="bookingData.courier_type"
                  type="text"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Receiver</span>
                <v-text-field
                  v-model="bookingData.receiver"
                  type="text"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Receiver Phone</span>
                <v-text-field
                  v-model="bookingData.receiver_phone"
                  type="number"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Receiver Address</span>
                <v-text-field
                  v-model="bookingData.receiver_address"
                  type="text"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Receiver Post Code</span>
                <v-text-field
                  v-model="bookingData.receiver_post_code"
                  type="number"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Type Order Sertifikat</span>
                <v-checkbox
                  color="orange darken-3"
                  v-model="bookingData.is_fisik"
                  true-value="1"
                  false-value="0"
                  hide-details
                  label="Fisik Sertifikat"
                >
                  <!-- <template v-slot:label>
                    <div>Fisik Sertifikat</div>
                  </template> -->
                </v-checkbox>
              </v-col>
            </v-row>
            <!-- ITEMS -->
            <v-row v-if="bookingData.is_fisik == 1">
              <div class="ml-5 body-1">Data Items</div>
              <v-col cols="12">
                <div>
                  Item Name
                </div>
                <v-text-field
                  v-model="bookingData.item.name"
                  outlined
                  filled
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Value
                </div>
                <v-text-field
                  v-model="bookingData.item.value"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Quantity
                </div>
                <v-text-field
                  v-model="bookingData.item.quantity"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Height
                </div>
                <v-text-field
                  v-model="bookingData.item.height"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Length
                </div>
                <v-text-field
                  v-model="bookingData.item.length"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Weight
                </div>
                <v-text-field
                  v-model="bookingData.item.weight"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Width
                </div>
                <v-text-field
                  v-model="bookingData.item.width"
                  outlined
                  filled
                  min="0"
                  type="number"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="
              $refs.bookingForm.reset();
                modalBooking = null;
                license = {};
              "
              >Batal</v-btn
            >
            <v-btn
              @click="booking"
              color="primary"
              elevation="0"
              :loading="isBooking"
              >Kirim</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="modalDestroy" width="500">
      <v-form ref="destroyForm" @submit.prevent="destroy()">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Hapus lisensi</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            Apakah anda yakin ingin menghapus lisensi
            <strong>{{ license.code }}</strong> ini?
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="
                modalDestroy = null;
                license = {};
              "
              >Batal</v-btn
            >
            <v-btn type="submit" color="primary" elevation="0">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialog.comfirmationResend" width="500">
      <v-form ref="destroyForm" @submit.prevent="resendLicensi()">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Kirim Ulang Licensi</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            Apakah anda yakin ingin mengirim ulang lisensi
            <strong>{{ license.code }}</strong> ini ke email <strong>{{ license.buyer ? license.buyer.email : '' }}</strong>?
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="
                dialog.comfirmationResend = false;
                license = {};">
              Batal
            </v-btn>
            <v-btn type="submit" color="primary" elevation="0" :loading="process.run">Kirim Ulang</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar v-model="toast.state" top :color="toast.color">
      {{ toast.message }}
    </v-snackbar>
  </v-row>
</template>

<script>
// import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "Cek-lisensi",
  data: () => ({
    isSearching: false,
    isBooking: false,
    isDeleting: false,
    dataLoading: null,
    listExcel: [],
    data: {},
    is_used: [
      {
        text: "Digunakan", 
        value: 1,
      },
      {
        text: "Belum digunakan", 
        value: 0,
      }
    ],
    is_sold: [
      {
        text: "Dipesan", 
        value: 1,
      },
      {
        text: "Belum dipesan", 
        value: 0,
      }
    ],
    is_sent: [
      {
        text: "Terkirim", 
        value: 1,
      },
      {
        text: "Tidak terkirim", 
        value: 0,
      }
    ],
    filter: {
      is_used: "",
      is_sold: "",
      is_sent: "",
      code: "",
      sold_date: "",
      start_date: "",
      end_date: ""
    },
    filternames: {
      is_used: "",
      is_sold: "",
      is_sent: "",
    },
    status: {
      date: false,
      code: false,
      is_sold: false,
      is_used: false,
      is_sent: false,
    },
    searching: "",
    license: {},
    detail: {},
    detailHistorySend: {},
    bookingData: {
      email: "",
      fullname: "",
      is_fisik: 0,
      wp_order_id: "",
      courier: "",
      courier_type: "",
      receiver: "",
      receiver_phone: "",
      receiver_address: "",
      receiver_post_code: "",
      item : {
        name: "",
        value: 0,
        quantity: 0,
        height: 0,
        length: 0,
        weight: 0,
        width: 0
      }
    },
    modalDestroy: null,
    modalBooking: null,
    dialog: {
      filtered: false,
      detail_license: false,
      picker_start: false,
      picker_end: false,
      history_sent: false,
      comfirmationResend: false,
    },
    toast: {
      state: false,
      color: "",
      message: ""
    },
    process: {
      run: false,
    },
  }),
  computed: {
    rules: () => ({
      required: (value) => !!value || "Tidak boleh kosong",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email tidak valid";
      },
    }),
    headers: () => [
      { text: "ID Sertifikat", value: "order_no" },
      { text: "Kode Lisensi", value: "code" },
      { text: "Pembeli", value: "buyer" },
      { text: "Pengguna", value: "user" },
      { text: "Status Kirim", value: "is_sended" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action" },
    ],
    filterSoldDate() {
      return this.filter.sold_date ? moment(this.filter.sold_date).format("DD MMMM YYYY") : ''
    },
    filterStartDate() {
      return this.filter.start_date ? moment(this.filter.start_date).format("DD MMMM YYYY") : ''
    },
    filterEndDate(){
      return this.filter.end_date ? moment(this.filter.end_date).format("DD MMMM YYYY") : ''
    },
    dateNow() {
      return moment().format("DMYYYYHHmmss")
    },
    columnsExcel() {
      return [ 
        {
          label: "Kode Lisensi",
          field: "code",
        }, 
        {
          label: "Nama Pembeli Lisensi",
          field: "buyer.fullname",
        }, 
        {
          label: "Email Pembeli Lisensi",
          field: "buyer.email",
        },
        {
          label: "Nama Pengguna Lisensi",
          field: "user.full_name",
        }, 
        {
          label: "Email Pengguna Lisensi",
          field: "user.email",
        }, 
        {
          label: "Tanggal Terjual",
          field: "sold_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
      ]
    },
    // ...mapGetters({ data: "license/data", params: "license/params" }),
  },
  created() {
    this.fetchData();
  },
  methods: {
    // # FUNCTION DETAIL
    detail_license(data){
      // console.log(data);
      this.detail = data
      this.dialog.detail_license =  true
    },

    detailSenderEmail (data) {
      this.dialog.history_sent = true
      this.detail = data
      this.detailHistorySend = data.sent_email
    },

    resetFilter () {
      this.fetchData();
      this.status = {
        code: false,
        is_sold: false,
        is_used: false,
        is_sent: false,
        date: false,
      }

      this.filter = {
        code: "",
        is_sold: "",
        is_used: "",
        is_sent: "",
        sold_date: "",
        start_date: "",
        end_date: "",
      }

      this.filternames = {}
    },

    async fetchData() {
      this.dataLoading = true;
      this.process.run = true
      
      if (this.filter.code != "") {
        this.status.code = true;
      }

      if (this.filter.is_used === 0 || this.filter.is_used === 1) {
        this.status.is_used = true;
        this.is_used.forEach(item => {
          if(this.filter.is_used == item.value) {
            this.filternames.is_used = item.text
          }
        });
      }

      if (this.filter.is_sold === 0 || this.filter.is_sold === 1) {
        this.status.is_sold = true;
        this.is_sold.forEach(item => {
          if(this.filter.is_sold == item.value) {
            this.filternames.is_sold = item.text
          }
        });
      }

      if (this.filter.is_sent === 0 || this.filter.is_sent === 1) {
        this.status.is_sent = true;
        this.is_sent.forEach(item => {
          if(this.filter.is_sent == item.value) {
            this.filternames.is_sent = item.text
          }
        });
      }

      if (this.filter.sold_date) {
        this.status.date = true;
      }


      await this.axios.get(`license/v1/list`, {
        params: {
          page: this.data.page, 
          limit: this.data.limit, 
          sort: 'used_at', 
          dir: 'desc',
          user_email: this.searching,
          code: this.filter.code,
          is_used: this.filter.is_used,
          is_sold: this.filter.is_sold,
          is_sent: this.filter.is_sent,
          sold_date: this.filter.sold_date,
        }
      })
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.data = res.data
          this.dataLoading = false;
          this.process.run = false

          this.fetchDataExcel()
        }
      })
      .catch((error) => {
        console.error(error)
        this.dataLoading = false
        this.process.run = false
        this.toast = {
          state: true,
          color: 'red',
          message: "Gagal mengambil data List"
        }

      })

    },


    async fetchDataExcel() {
      // this.dataLoading = true;

      await this.axios.get(`license/v1/list`, {
        params: {
          limit: -1, 
          sort: 'used_at', 
          dir: 'desc',
          user_email: this.searching,
          code: this.filter.code,
          is_used: this.filter.is_used,
          is_sold: this.filter.is_sold,
          is_sent: this.filter.is_sent,
          sold_date: this.filter.sold_date,
        }
      })
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.dataLoading = false;
          this.process.run = false
          this.listExcel = res.data.list
        }
      })
      .catch((error) => {
        console.error(error)
        this.dataLoading = false
        this.process.run = false
        this.toast = {
          state: true,
          color: 'red',
          message: "Gagal mengambil export excel"
        }
      })

    },

    handleCloseChips(state) {
      switch (state) {
        case "date":
          this.filter.start_date = ""
          this.filter.end_date = ""
          this.filter.sold_date = ""
          this.status.date = false
          break;
        case "code":
          this.filter.code = ""
          this.status.code = false
          break;
        case "is_used":
          this.filter.is_used = ""
          this.status.is_used = false
          break;
        case "is_sold":
          this.filter.is_sold = ""
          this.status.is_sold = false
          break;
        case "is_sent":
          this.filter.is_sent = ""
          this.status.is_sent = false
          break;
      }

      this.fetchData()
    },
    async search() {
      this.dialog.filtered = false;
      await this.fetchData()
    },
    async resendLicensi() {
      this.process.run = true
      await this.axios.post(`license/v1/resend-buyer`,{
        code: this.license.code
      }).then((response) => {
        let res = response.data
        console.log(res);
        if (res.status == 200) {
          this.process.run = false
          this.dialog.comfirmationResend = false;
          this.toast = {
            state: true,
            color: "success",
            message: "Berhasil kirim ulang licensi"
          }
        }else {
          this.process.run = false
          this.dialog.comfirmationResend = false;
          this.toast = {
            state: true,
            color: "error",
            message: `Gagal kirim ulang licensi. ${res.message}`
          }
        }
      }).catch((error) => {

        if (error.response) {
          let errorData = error.response.data;
          let errorCode = error.response.status;

          if (errorCode === 401) {
            window.location.reload();
            this.toast = {
            state: true,
            color: "error",
            message: `${errorData.message}`
          }
          }
        }else{
          this.toast = {
            state: true,
            color: "error",
            message: `Terkendala Masalah Server`
          }
        }
        this.dialog.comfirmationResend = false;
        this.process.run = false
      })
    },
    action(item, action) {
      this.license = item;
      switch (action) {
        case "delete":
          this.modalDestroy = true;
          break;
        case "book":
          this.modalBooking = true;
          break;
        case "resend":
          this.dialog.comfirmationResend = true;
          break;
      }
    },
    booking() {
      if (this.$refs.bookingForm.validate()) {
        this.isBooking = true;
        this.$store
        .dispatch("license/book", {
          code: this.license.code,
          email: this.bookingData.email,
          fullname: this.bookingData.fullname,
          is_fisik: parseInt(this.bookingData.is_fisik),
          wp_order_id: parseInt(this.bookingData.wp_order_id),
          courier: this.bookingData.courier,
          courier_type: this.bookingData.courier_type,
          receiver: this.bookingData.receiver,
          receiver_phone: this.bookingData.receiver_phone,
          receiver_address: this.bookingData.receiver_address,
          receiver_post_code: parseInt(this.bookingData.receiver_post_code),
          item: {
            name: this.bookingData.item.name,
            value: parseInt(this.bookingData.item.value),
            quantity: parseInt(this.bookingData.item.quantity),
            height: parseInt(this.bookingData.item.height),
            length: parseInt(this.bookingData.item.length),
            weight: parseInt(this.bookingData.item.weight),
            width: parseInt(this.bookingData.item.width)
          }
        })
        .then((response) => {
          response.data
          this.$set(this.toast, "state", true);
          this.$set(this.toast, "color", "#4CAF50");
          this.$set(this.toast, "message", "Lisensi berhasil dibooking");
        })
        .catch((error) => {
          let message = error.message;
          if (error.response) {
            message = error.response.data.message;
          }
          this.$set(this.toast, "state", true);
          this.$set(this.toast, "color", "error");
          this.$set(this.toast, "message", "Error: " + message);
        })
        .finally(() => {
          this.isBooking = false;
          this.modalBooking = false;
          this.code = "";
          this.$refs.form.reset();
        });
      }
    },
    destroy() {
      if (this.$refs.destroyForm.validate()) {
        this.isDeleting = true;
        this.$store
          .dispatch("license/delete", this.license.code)
          .then(() => {
            this.$set(this.toast, "state", true);
            this.$set(this.toast, "color", "#4CAF50");
            this.$set(this.toast, "message", "Lisensi berhasil dihapus");
          })
          .catch((error) => {
            let message = error.message;
            if (error.response) {
              message = error.response.data.message;
            }
            this.$set(this.toast, "state", true);
            this.$set(this.toast, "color", "error");
            this.$set(this.toast, "message", "Error: " + message);
          })
          .finally(() => {
            this.isDeleting = false;
            this.modalDestroy = false;
            this.$refs.form.reset();
          });
      }
    },
    dateTimeFormat(date) {
      date = new Date(date);
      return date.toLocaleString("ID").replaceAll("/", "-");
    },
    dateFormat(date) {
      date = new Date(date);
      return date.toLocaleDateString("ID");
    },
    validity(item) {
      if (item.start_date && item.end_date) {
        return `Berlaku mulai ${this.dateTimeFormat(
          item.start_date
        )} s/d ${this.dateTimeFormat(item.end_date)}`;
      } else if (item.start_date) {
        return `Berlaku mulai ${this.dateTimeFormat(item.start_date)}`;
      } else if (item.end_date) {
        return `Berlaku sampai dengan ${this.dateTimeFormat(item.end_date)}`;
      } else {
        return "";
      }
    },
  },
  watch: {
    searching: function (newval) {
      this.searching = newval;
      setTimeout(() => {
        this.fetchData()
      }, 800);
    },
    "data.limit": function () {
      this.fetchData();
    },
    "data.page": function () {
      this.fetchData();
    },
  },
};
</script>