<template>
  <div>

    <!-- NOTIFICATION -->
    <v-snackbar :color="notification.color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog.preview" max-width="500px" max-height="500px">
      <v-img
        :src="imagePreview"
        :lazy-src="imagePreview"
        class="grey lighten-2">

        <template v-slot:placeholder>

          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center">

            <v-progress-circular
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-dialog>

    <v-dialog v-model="dialog.confirmSend" max-width="500px">
      <v-card class="rounded-lg">
        <v-card-title class="d-flex justify-center align-center text-h5">
          Kirim Sertifikat
        </v-card-title>
        <v-card-text class="text-center my-4">
          Pastikan foto KTM  dengan foto tangkapan layar merupakan orang yang sama
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn
            @click="dialog.confirmSend = false"
            :loading="process.sendCertificate"
            elevation="0">
            Batalkan
          </v-btn>
          <v-btn
            @click="sendCertificate()"
            :loading="process.sendCertificate"
            color="primary"
            elevation="0">
            Kirim Sekarang
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div
      style="
        font-weight: 700;
        font-size: 24px;
        color: #3C4858">
      Data Detail Test - {{ $route.params.lisenci }}
    </div>

    <v-btn
      color="primary"
      small
      @click="$router.go(-1)"
      class="pl-0"
      text>
      <v-icon>$prev</v-icon>
      Kembali
    </v-btn>


    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col>
            <div
              style="
                font-size: 24px;
                font-weight: 500;">
              Biodata
            </div>
            <v-simple-table class="py-5" dense>

              <template>

                <tbody>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Name</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0">
                      {{ detailMember.member.fullname ? detailMember.member.fullname : '-' }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">NIM</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize">
                      {{ detailMember.member.meta ? detailMember.member.meta.nim : '-' }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Place</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize">
                      {{ detailMember.member.place_of_birth ? detailMember.member.place_of_birth : '-' }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Date of birth</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0">
                      {{ detailMember.member.date_of_birth | dateOnly }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Test Date</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize">
                      {{ detailMember.start_test | datetime }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Test ID</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize">
                      {{ detailMember.order_no ? detailMember.order_no.replace("YEC", "PBUM") : '-' }}
                    </th>
                  </tr>

                </tbody>

              </template>

            </v-simple-table>
          </v-col>
          <v-col cols="7">
            <div
              style="
                font-size: 24px;
                font-weight: 500;">
              Results
            </div>
            <v-simple-table class="py-5" dense>

              <template>

                <tbody>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Reading</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0" style="flex: 1">
                      {{ detailMember.section.reading.total_point }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Submited on {{ detailMember.section.reading.end_test_at | datetime }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Structure</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize" style="flex: 1">
                      {{ detailMember.section.structure.total_point }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Submited on {{ detailMember.section.structure.end_test_at | datetime }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Listening</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0" style="flex: 1">
                      {{ detailMember.section.listening.total_point }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Submited on {{ detailMember.section.listening.end_test_at | datetime }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Total Correct Point</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize" style="flex: 1">
                      {{ (detailMember.section.reading.total_point + detailMember.section.structure.total_point + detailMember.section.listening.total_point) }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Tes TOEFL Equivalent Test Score for TOEFL</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize" style="flex: 1">
                      {{ detailMember.score ? detailMember.score : '-'}}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Description</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize" style="flex: 1">
                      {{ '-' }}
                    </th>
                  </tr>

                </tbody>

              </template>

            </v-simple-table>
          </v-col>
        </v-row>
        <v-row v-if="detailMember.member.meta">
          <v-col cols="4">
            <div class="body-1 font-weight-medium mb-2">Foto KTM</div>
            <v-img 
              :src="detailMember.member.meta.ktm"
              @click="openPreview(detailMember.member.meta.ktm)"
              style="cursor: pointer;"
              height="200"
              cover>
            </v-img>
          </v-col>
          <v-col cols="4">
            <div class="body-1 font-weight-medium mb-2">Foto Peserta memegang KTM</div>
            <v-img 
              :src="detailMember.member.meta.ktm_user"
              @click="openPreview(detailMember.member.meta.ktm_user)"
              style="cursor: pointer;"
              height="200"
              cover>
            </v-img>
          </v-col>
          <v-col cols="4">
            <div class="body-1 font-weight-medium mb-2">Foto Peserta Saat test</div>
            <v-img 
              :src="list.listening.image.slice(0, 1)[0]"
              @click="openPreview(list.listening.image.slice(0, 1)[0])"
              style="cursor: pointer;"
              height="200"
              cover>
            </v-img>
          </v-col>
        </v-row>
        <v-row v-show="user_auth && user_auth.role.slug == 'superadmin'">
          <v-col>
            <v-btn
              elevation="0"
              class="text-capitalize mr-3"
              :loading="process.sendCertificate"
              @click="warningCertificate()">
              Kirimkan Redaksi Penolakan
            </v-btn>
            <v-btn
              elevation="0"
              class="text-capitalize white--text"
              color="primary"
              :loading="process.sendCertificate"
              @click="dialog.confirmSend = true">
              Kirimkan Sertifikat
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-tabs v-model="tab" class="pt-5">
      <v-tab>
        Foto Peserta Tes
      </v-tab>
      <v-tab>
        Foto buka tab baru
      </v-tab>
      <v-tab>
        Foto tangkapan wajah
      </v-tab>
      <v-tab>
        Rekaman Suara
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">

      <v-tab-item>
        <v-card style="background-color: #F6F7F9 !important;">
          <div style="font-size: 14px;" class="py-7">
            Berikut merupakan hasil tangkapan foto diri peserta tes pada saat pengerjaan tes tiap-tiap sesi
          </div>
          <v-alert text warning class="col-md-7" dense v-if="detailMember.settings.use_face_detection">
            <span class="fs-14 color-warning-cutsom">
              Peserta test mensetujui Bahwa Kamera tidak berfungsi dengan stabil 
            </span>
          </v-alert>

          <!-- Reading -->
          <div style="font-size: 18px;font-weight:500" class="py-5">
            Reading
            <v-btn
              color="primary"
              elevation="0"
              @click="save_featured(selected[0])"
              v-if="selected[0].images.length > 0"
              x-small>
              Save selected photo as primary
            </v-btn>
          </div>
          <v-card-text v-if="list.reading.image.length < 1" class="px-0 pt-16 text-center">
            <v-img
              width="300"
              class="mx-auto"
              :src="require('@/assets/image/data_empty.png')">
            </v-img>
            <p class="mt-7 text-h6">
              Data Capture Image Reading tidak tersedia
            </p>
          </v-card-text>
          <v-card-text v-else class="px-0 py-0">
            <v-item-group max="2" multiple v-model="selected[0].images">
              <v-row>
                <v-col
                  v-for="(n, i) in see_more.reading ? list.reading.image : list.reading.image.slice(0, 3)"
                  :key="i"
                  class="d-flex child-flex"
                  cols="4">

                  <v-item :value="n" v-slot="{ active, toggle }">
                    <v-img
                      :src="n"
                      :lazy-src="n"
                      height="200"
                      @click="toggle"
                      :style="active ? 'border: 5px solid #652065 !important;cursor: pointer;' : 'cursor: pointer;'"
                      class="grey lighten-2">

                      <div 
                        v-if="active"
                        class="pa-1 d-flex align-center white--text justify-center"
                        style="
                          z-index: 99999;
                          width: 40px; 
                          height: 40px;
                          position: absolute; 
                          right: 0;">
                        <v-icon color="primary">$checkboxOn</v-icon>
                      </div>

                      <template v-slot:placeholder>

                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center">

                          <v-progress-circular
                            indeterminate
                            color="primary">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-item>

                </v-col>
              </v-row>
              <div v-if="list.reading.image.length > 3" class="d-flex justify-center align-center mt-7">
                <v-btn
                  color="primary"
                  elevation="0"
                  small
                  @click="see_more.reading = !see_more.reading">
                  {{ see_more.reading ? 'See Less' : 'See More' }}
                </v-btn>
              </div>
            </v-item-group>
            
          </v-card-text>

          <!-- Structure -->
          <div style="font-size: 18px;font-weight:500" class="py-5">
            Structure
            <v-btn
              color="primary"
              elevation="0"
              @click="save_featured(selected[1])"
              v-if="selected[1].images.length > 0"
              x-small>
              Save selected photo as primary
            </v-btn>
          </div>
          <v-card-text v-if="list.structure.image.length < 1" class="px-0 pt-16 text-center">
            <v-img
              width="300"
              class="mx-auto"
              :src="require('@/assets/image/data_empty.png')">
            </v-img>
            <p class="mt-7 text-h6">
              Data Capture Image Structure tidak tersedia
            </p>
          </v-card-text>
          <v-card-text v-else class="px-0 py-0">

            <v-item-group max="2" multiple v-model="selected[1].images">
              <v-row>
                <v-col
                  v-for="(n,i) in see_more.structure ? list.structure.image : list.structure.image.slice(0, 3)"
                  :key="i"
                  class="d-flex child-flex"
                  cols="4">
                
                  <v-item :value="n" v-slot="{ active, toggle }">
                    <v-img
                      :src="n"
                      :lazy-src="n"
                      height="200"
                      @click="toggle"
                      :style="active ? 'border: 5px solid #652065 !important;cursor: pointer;' : 'cursor: pointer;'"
                      class="grey lighten-2">

                      <div 
                        v-if="active"
                        class="pa-1 d-flex align-center white--text justify-center"
                        style="
                          z-index: 99999;
                          width: 40px; 
                          height: 40px;
                          position: absolute; 
                          right: 0;">
                        <v-icon color="primary">$checkboxOn</v-icon>
                      </div>

                      <template v-slot:placeholder>

                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center">

                          <v-progress-circular
                            indeterminate
                            color="primary">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-item>

                </v-col>
              </v-row>
              <div v-if="list.structure.image.length > 3" class="d-flex justify-center align-center mt-7">
                <v-btn
                  color="primary"
                  elevation="0"
                  small
                  @click="see_more.structure = !see_more.structure">
                  {{ see_more.structure ? 'See Less' : 'See More' }}
                </v-btn>
              </div>
            </v-item-group>
          </v-card-text>

          <!-- Listening -->
          <div style="font-size: 18px;font-weight:500" class="py-5">
            Listening
            <v-btn
              color="primary"
              elevation="0"
              @click="save_featured(selected[2])"
              v-if="selected[2].images.length > 0"
              x-small>
              Save selected photo as primary
            </v-btn>
          </div>
          <v-card-text v-if="list.listening.image.length < 1" class="px-0 pt-16 text-center">
            <v-img
              width="300"
              class="mx-auto"
              :src="require('@/assets/image/data_empty.png')">
            </v-img>
            <p class="mt-7 text-h6">
              Data Capture Image Listening tidak tersedia
            </p>
          </v-card-text>
          <v-card-text v-else class="px-0 py-0">
            <v-item-group max="2" multiple v-model="selected[2].images">
              <v-row>
                <v-col
                  v-for="(n,i) in see_more.listening ? list.listening.image : list.listening.image.slice(0, 3)"
                  :key="i"
                  class="d-flex child-flex"
                  cols="4">

                  <v-item :value="n" v-slot="{ active, toggle }">
                    <v-img
                      :src="n"
                      :lazy-src="n"
                      height="200"
                      @click="toggle"
                      :style="active ? 'border: 5px solid #652065 !important;cursor: pointer;' : 'cursor: pointer;'"
                      class="grey lighten-2">

                      <div 
                        v-if="active"
                        class="pa-1 d-flex align-center white--text justify-center"
                        style="
                          z-index: 99999;
                          width: 40px; 
                          height: 40px;
                          position: absolute; 
                          right: 0;">
                        <v-icon color="primary">$checkboxOn</v-icon>
                      </div>

                      <template v-slot:placeholder>

                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center">

                          <v-progress-circular
                            indeterminate
                            color="primary">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-item>

                </v-col>
              </v-row>
              <div v-if="list.listening.image.length > 3" class="d-flex justify-center align-center mt-7">
                <v-btn
                  color="primary"
                  elevation="0"
                  small
                  @click="see_more.listening = !see_more.listening">
                  {{ see_more.listening ? 'See Less' : 'See More' }}
                </v-btn>
              </div>
            </v-item-group>

          </v-card-text>
          
        </v-card>
      </v-tab-item>

      <!-- NEW TAB DETECTION -->
      <v-tab-item>
        <v-card style="background-color: #F6F7F9 !important;">
          <p style="font-size: 14px;" class="pt-7">
            Hasil tangkapan layar peserta test pada saat pengerjaan tes di semua sesi (Reading-Structure-Listening)
          </p>
          
          <!-- Reading -->
          <div style="font-size: 18px;font-weight:500" class="py-5">
            Reading
            <p style="font-size: 14px;">
              Pada saat tes berlangsung peserta test telah membuka tab baru sebanyak : {{ list.reading.violation.open_new_tab.count }}
            </p>
          </div>
          <v-card-text v-if="list.reading.violation.open_new_tab.data.length < 1" class="px-0 pt-16 text-center">
            <v-img
              width="300"
              class="mx-auto"
              :src="require('@/assets/image/data_empty.png')">
            </v-img>
            <p class="mt-7 text-h6">
              Data Capture Image Reading tidak tersedia
            </p>
          </v-card-text>
          <v-card-text v-else class="px-0 py-0">

            <v-row>
              <v-col
                v-for="(n, i) in see_more.open_new_tab.reading ? list.reading.violation.open_new_tab.data : list.reading.violation.open_new_tab.data.slice(0, 3)"
                :key="i"
                class="d-flex child-flex"
                cols="4">

                  <v-img
                    :src="n.media.url"
                    :lazy-src="n.media.url"
                    @click="openPreview(n.media.url)"
                    height="200"
                    class="grey lighten-2 cursor-pointer">

                    <template v-slot:placeholder>

                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">

                        <v-progress-circular
                          indeterminate
                          color="primary">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

              </v-col>
            </v-row>
            
            <div v-if="list.reading.violation.open_new_tab.data.length > 3" class="d-flex justify-center align-center mt-7">
              <v-btn
                color="primary"
                elevation="0"
                small
                @click="see_more.open_new_tab.reading = !see_more.open_new_tab.reading">
                {{ see_more.open_new_tab.reading ? 'See Less' : 'See More' }}
              </v-btn>
            </div>
          </v-card-text>

          <!-- Structure -->
          <div style="font-size: 18px;font-weight:500" class="py-5">
            Structure
            <p style="font-size: 14px;">
              Pada saat tes berlangsung peserta test telah membuka tab baru sebanyak : {{ list.structure.violation.open_new_tab.count }}
            </p>
          </div>
          <v-card-text v-if="list.structure.violation.open_new_tab.data.length < 1" class="px-0 pt-16 text-center">
            <v-img
              width="300"
              class="mx-auto"
              :src="require('@/assets/image/data_empty.png')">
            </v-img>
            <p class="mt-7 text-h6">
              Data Capture Image Structure tidak tersedia
            </p>
          </v-card-text>
          <v-card-text v-else class="px-0 py-0">
            <v-row>
              <v-col
                v-for="(n,i) in see_more.open_new_tab.structure ? list.structure.violation.open_new_tab.data : list.structure.violation.open_new_tab.data.slice(0, 3)"
                :key="i"
                class="d-flex child-flex"
                cols="4">
              
                  <v-img
                    :src="n.media.url"
                    :lazy-src="n.media.url"
                    @click="openPreview(n.media.url)"
                    height="200"
                    class="grey lighten-2 cursor-pointer">

                    <template v-slot:placeholder>

                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">

                        <v-progress-circular
                          indeterminate
                          color="primary">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

              </v-col>
            </v-row>
            <div v-if="list.structure.violation.open_new_tab.data.length > 3" class="d-flex justify-center align-center mt-7">
              <v-btn
                color="primary"
                elevation="0"
                small
                @click="see_more.open_new_tab.structure = !see_more.open_new_tab.structure">
                {{ see_more.open_new_tab.structure ? 'See Less' : 'See More' }}
              </v-btn>
            </div>
          </v-card-text>

          <!-- Listening -->
          <div style="font-size: 18px;font-weight:500" class="py-5">
            Listening
            <p style="font-size: 14px;">
              Pada saat tes berlangsung peserta test telah membuka tab baru sebanyak : {{ list.listening.violation.open_new_tab.count }}
            </p>
          </div>
          <v-card-text v-if="list.listening.violation.open_new_tab.data.length < 1" class="px-0 pt-16 text-center">
            <v-img
              width="300"
              class="mx-auto"
              :src="require('@/assets/image/data_empty.png')">
            </v-img>
            <p class="mt-7 text-h6">
              Data Capture Image Listening tidak tersedia
            </p>
          </v-card-text>
          <v-card-text v-else class="px-0 py-0">

            <v-row>
              <v-col
                v-for="(n,i) in see_more.open_new_tab.listening ? list.listening.violation.open_new_tab.data : list.listening.violation.open_new_tab.data.slice(0, 3)"
                :key="i"
                class="d-flex child-flex"
                cols="4">

                  <v-img
                    :src="n.media.url"
                    :lazy-src="n.media.url"
                    @click="openPreview(n.media.url)"
                    height="200"
                    class="grey lighten-2 cursor-pointer">

                    <template v-slot:placeholder>

                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">

                        <v-progress-circular
                          indeterminate
                          color="primary">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

              </v-col>
            </v-row>
            <div v-if="list.listening.violation.open_new_tab.data.length > 3" class="d-flex justify-center align-center mt-7">
              <v-btn
                color="primary"
                elevation="0"
                small
                @click="see_more.open_new_tab.listening = !see_more.open_new_tab.listening">
                {{ see_more.open_new_tab.listening ? 'See Less' : 'See More' }}
              </v-btn>
            </div>
          </v-card-text> 

        </v-card>
      </v-tab-item>

      <!-- FACE DETECTION -->
      <v-tab-item>
        <v-card style="background-color: #F6F7F9 !important;">
          <p style="font-size: 14px;" class="pt-7">
            Berikut merupakan hasil foto tangkapan layar wajah tidak terdeteksi pada saat pengerjaan tes tiap-tiap sesi
          </p>
          <v-alert text warning class="col-md-7" dense v-if="detailMember.settings.use_face_detection">
            <span class="fs-14 color-warning-cutsom">
              Peserta test mensetujui Bahwa Kamera tidak berfungsi dengan stabil 
            </span>
          </v-alert>
          
          <!-- Reading -->
          <div style="font-size: 18px;font-weight:500" class="py-5">
            Reading
            <p style="font-size: 14px;">
              Pada saat tes berlangsung kamera tidak dapat mendeteksi wajah peserta test sebanyak : {{ list.reading.violation.face.count }}
            </p>
          </div>
          <v-card-text v-if="list.reading.violation.face.data.length < 1" class="px-0 pt-16 text-center">
            <v-img
              width="300"
              class="mx-auto"
              :src="require('@/assets/image/data_empty.png')">
            </v-img>
            <p class="mt-7 text-h6">
              Data Capture Image Reading tidak tersedia
            </p>
          </v-card-text>
          <v-card-text v-else class="px-0 py-0">

            <v-row>
              <v-col
                v-for="(n, i) in see_more.face.reading ? list.reading.violation.face.data : list.reading.violation.face.data.slice(0, 3)"
                :key="i"
                class="d-flex child-flex"
                cols="4">

                  <v-img
                    :src="n.media.url"
                    :lazy-src="n.media.url"
                    @click="openPreview(n.media.url)"
                    height="200"
                    class="grey lighten-2 cursor-pointer">

                    <template v-slot:placeholder>

                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">

                        <v-progress-circular
                          indeterminate
                          color="primary">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

              </v-col>
            </v-row>
            
            <div v-if="list.reading.violation.face.data.length > 3" class="d-flex justify-center align-center mt-7">
              <v-btn
                color="primary"
                elevation="0"
                small
                @click="see_more.face.reading = !see_more.face.reading">
                {{ see_more.face.reading ? 'See Less' : 'See More' }}
              </v-btn>
            </div>
          </v-card-text>

          <!-- Structure -->
          <div style="font-size: 18px;font-weight:500" class="py-5">
            Structure
            <p style="font-size: 14px;">
              Pada saat tes berlangsung kamera tidak dapat mendeteksi wajah peserta test sebanyak : {{ list.structure.violation.face.count }}
            </p>
          </div>
          <v-card-text v-if="list.structure.violation.face.data.length < 1" class="px-0 pt-16 text-center">
            <v-img
              width="300"
              class="mx-auto"
              :src="require('@/assets/image/data_empty.png')">
            </v-img>
            <p class="mt-7 text-h6">
              Data Capture Image Structure tidak tersedia
            </p>
          </v-card-text>
          <v-card-text v-else class="px-0 py-0">
            <v-row>
              <v-col
                v-for="(n,i) in see_more.face.structure ? list.structure.violation.face.data : list.structure.violation.face.data.slice(0, 3)"
                :key="i"
                class="d-flex child-flex"
                cols="4">
              
                  <v-img
                    :src="n.media.url"
                    :lazy-src="n.media.url"
                    @click="openPreview(n.media.url)"
                    height="200"
                    class="grey lighten-2 cursor-pointer">

                    <template v-slot:placeholder>

                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">

                        <v-progress-circular
                          indeterminate
                          color="primary">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

              </v-col>
            </v-row>
            <div v-if="list.structure.violation.face.data.length > 3" class="d-flex justify-center align-center mt-7">
              <v-btn
                color="primary"
                elevation="0"
                small
                @click="see_more.face.structure = !see_more.face.structure">
                {{ see_more.face.structure ? 'See Less' : 'See More' }}
              </v-btn>
            </div>
          </v-card-text>

          <!-- Listening -->
          <div style="font-size: 18px;font-weight:500" class="py-5">
            Listening
            <p style="font-size: 14px;">
              Pada saat tes berlangsung kamera tidak dapat mendeteksi wajah peserta test sebanyak : {{ list.listening.violation.face.count }}
            </p>
          </div>
          <v-card-text v-if="list.listening.violation.face.data.length < 1" class="px-0 pt-16 text-center">
            <v-img
              width="300"
              class="mx-auto"
              :src="require('@/assets/image/data_empty.png')">
            </v-img>
            <p class="mt-7 text-h6">
              Data Capture Image Listening tidak tersedia
            </p>
          </v-card-text>
          <v-card-text v-else class="px-0 py-0">

            <v-row  >
              <v-col
                v-for="(n,i) in see_more.face.listening ? list.listening.violation.face.data : list.listening.violation.face.data.slice(0, 3)"
                :key="i"
                class="d-flex child-flex"
                cols="4">

                  <v-img
                    :src="n.media.url"
                    :lazy-src="n.media.url"
                    @click="openPreview(n.media.url)"
                    height="200"
                    class="grey lighten-2 cursor-pointer">

                    <template v-slot:placeholder>

                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">

                        <v-progress-circular
                          indeterminate
                          color="primary">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

              </v-col>
            </v-row>
            <div v-if="list.listening.violation.face.data.length > 3" class="d-flex justify-center align-center mt-7">
              <v-btn
                color="primary"
                elevation="0"
                small
                @click="see_more.face.listening = !see_more.face.listening">
                {{ see_more.face.listening ? 'See Less' : 'See More' }}
              </v-btn>
            </div>
          </v-card-text> 

        </v-card>
      </v-tab-item>

      <!-- {{ list.listening.violation.speech }} -->

      <!-- SPEECH DETECTION -->
      <v-tab-item>
        <v-card style="background-color: #F6F7F9 !important;">
          <p style="font-size: 14px;" class="pt-7">
            Berikut merupakan hasil rekaman Suara peserta lebih dari 3 kata pada saat pengerjaan tes tiap-tiap sesi
          </p>
          <v-alert text warning class="col-md-7" dense v-if="detailMember.settings.use_speech_detection">
            <span class="fs-14 color-warning-cutsom">
              Peserta test mensetujui bahwa Microphone tidak berfungsi dengan stabil 
            </span>
          </v-alert>
          
          <!-- Reading -->
          <div style="font-size: 18px;font-weight:500" class="pt-5">
            Reading
            <p style="font-size: 14px;">
              Pada saat tes berlangsung peserta test berbicara lebih dari 3 kata sebanyak : {{ list.reading.violation.speech.count }}
            </p>
          </div>
          <v-card-text class="px-0 py-0">

            <ol type="1">
              <li v-for="(n, i) in see_more.speech.reading ? list.reading.violation.speech.data : list.reading.violation.speech.data"
                :key="i">
                {{ n.text_voice }}
              </li>
            </ol>

          </v-card-text>

          <!-- Structure -->
          <div style="font-size: 18px;font-weight:500" class="pt-5">
            Structure
            <p style="font-size: 14px;">
              Pada saat tes berlangsung peserta test berbicara lebih dari 3 kata sebanyak : {{ list.structure.violation.speech.count }}
            </p>
          </div>
          
          <v-card-text class="px-0 pt-0 pb-5">
            
            <ol type="1">
              <li v-for="(n, i) in see_more.speech.structure ? list.structure.violation.speech.data : list.structure.violation.speech.data"
                :key="i">
                {{ n.text_voice }}
              </li>
            </ol>

          </v-card-text>

        </v-card>
      </v-tab-item>

    </v-tabs-items>

  </div>
</template>

<script>
// import { audioPlayer } from 'vue-md-player'
export default {
  components: {
    // audioPlayer,
    // videoPlayer
  },
  data: () => ({
    tab: null,
    imagePreview: "",
    list: {},
    see_more: {
      reading: false,
      structure: false,
      listening: false,
      open_new_tab: {
        reading: false,
        structure: false,
        listening: false
      },
      face: {
        reading: false,
        structure: false,
        listening: false
      },
      speech: {
        reading: false,
        structure: false,
        listening: false
      }
    },
    detailMember: {},
    selected: [
      {
        section : "reading",
        images : []
      },
      {
        section : "structure",
        images : []
      },
      {
        section : "listening",
        images : []
      }
    ],
    edited: {
      reading: false,
      structure: false,
      listening: false
    },
    dialog: {
      preview: false,
      confirmSend: false,
    },
    process: {
      run: false,
      sendCertificate: false,
    },
    notification: {
      state: false,
      text: "",
      color: ""
    },
  }),
  created(){
    if (this.$route.name.includes("capture-detail")) {
      this.$emit("page-changed", 1, { title: "Detail Capture Image", link: "#" });
    }
  },
  computed: {
    user_auth() {
      return JSON.parse(localStorage.getItem('ytc_auth'))

      // if (user_auth && user_auth.role.slug == 'superadmin') {
    }
  },
  mounted() {
    this.initialize()
    this.getMember()
  },
  methods: {
    openPreview(image_url){
      this.dialog.preview = true
      this.imagePreview = image_url
    },
    async sendCertificate() {
      this.process.sendCertificate = true
      await this.axios.get(`/users/v1/admin/order/sertificate/send-email/${this.$route.params.lisenci}`)
      .then((res) => res.data)
      .then((res) => {
        if (res.status == 200) {
          this.notification = {
            state: true,
            color: "success",
            text: "Sertifikat telah berhasil dikirim"
          }
          this.dialog.confirmSend = false
          this.process.sendCertificate = false
        }else{
          this.notification = {
            state: true,
            color: "red",
            text: "Gagal Mengirimkan Sertifikat"
          }
          this.dialog.confirmSend = false
          this.process.sendCertificate = false
        }
      }).catch(error => {
        let errorData = error.response.data;
        this.notification = {
          state: true,
          color: "red",
          text: errorData.message ? errorData.message : "Terjadi Kesalahan Server"
        }
        this.dialog.confirmSend = false
        this.process.sendCertificate = false
      })
    },
    async warningCertificate() {
      this.process.sendCertificate = true
      await this.axios.get(`/users/v1/admin/order/sertificate/warning/${this.$route.params.lisenci}`)
      .then((res) => res.data)
      .then((res) => {
        if (res.status == 200) {
          this.notification = {
            state: true,
            color: "success",
            text: "Redaksi Penolakan berhasil dikirim"
          }
          this.dialog.confirmSend = false
          this.process.sendCertificate = false
        }else{
          this.notification = {
            state: true,
            color: "red",
            text: "Gagal Mengirimkan Redaksi Penolakan"
          }
          this.dialog.confirmSend = false
          this.process.sendCertificate = false
        }
      }).catch(error => {
        let errorData = error.response.data;
        this.notification = {
          state: true,
          color: "red",
          text: errorData.message ? errorData.message : "Terjadi Kesalahan Server"
        }
        this.dialog.confirmSend = false
        this.process.sendCertificate = false
      })
    },
    async save_featured(selected){
      // console.log(this.selected);
      let array_selected = []
      array_selected.push(selected)
      await this.axios.put(`/users/v1/admin/order/media/featured/${this.$route.params.lisenci}`, array_selected)
      .then((res) => res.data)
      .then((res) => {
        if (res.status == 200) {
          this.notification = {
            state: true,
            color: "success",
            text: "Berhasil Menyimpan Primary Photo"
          }
          this.selected[0].images = []
          this.selected[1].images = []
          this.selected[2].images = []
        }else{
          this.notification = {
            state: true,
            color: "red",
            text: "Gagal Menyimpan Primary Photo"
          }
          this.selected[0].images = []
          this.selected[1].images = []
          this.selected[2].images = []
        }
      }).catch(error => {
        let errorData = error.response.data;
        this.notification = {
          state: true,
          color: "red",
          text: errorData.message ? errorData.message : "Terjadi Kesalahan Server"
        }
        this.selected[0].images = []
        this.selected[1].images = []
        this.selected[2].images = []
      })
    },
    async getMember(){
      this.process.run = true;
      // this.$set(this.listData, "list", []);

      this.axios.get(`/users/v1/admin/order/detail-full/${this.$route.params.id}`)
        .then((res) => res.data)
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            this.detailMember = res.data
            this.process.run = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.process.run = false;
          this.dialog.filtered = false;
          this.certifLoading = false;
          let errorData = error.response.data;
          // console.log(errorData);
          this.notification = {
            state: true,
            color: "red",
            text: errorData.message
          }
        });
    },
    async initialize() {
      this.process.run = true

      await this.axios.get(`/users/v1/admin/order/media/${this.$route.params.lisenci}`)
      .then((res) => res.data)
      .then((res) => {
        // Listening
        res.data.listening.image = res.data.listening.image == null ? [] : res.data.listening.image;
        res.data.listening.violation.open_new_tab.data = res.data.listening.violation.open_new_tab.data == null ? [] : res.data.listening.violation.open_new_tab.data;
        res.data.listening.violation.face.data = res.data.listening.violation.face.data == null ? [] : res.data.listening.violation.face.data;
        res.data.listening.violation.speech.data = res.data.listening.violation.speech.data == null ? [] : res.data.listening.violation.speech.data;
        // Reading
        res.data.reading.image = res.data.reading.image == null ? [] : res.data.reading.image;
        res.data.reading.violation.open_new_tab.data = res.data.reading.violation.open_new_tab.data == null ? [] : res.data.reading.violation.open_new_tab.data;
        res.data.reading.violation.face.data = res.data.reading.violation.face.data == null ? [] : res.data.reading.violation.face.data;
        res.data.reading.violation.speech.data = res.data.reading.violation.speech.data == null ? [] : res.data.reading.violation.speech.data;
        // Structure
        res.data.structure.image = res.data.structure.image == null ? [] : res.data.structure.image;
        res.data.structure.violation.open_new_tab.data = res.data.structure.violation.open_new_tab.data == null ? [] : res.data.structure.violation.open_new_tab.data;
        res.data.structure.violation.face.data = res.data.structure.violation.face.data == null ? [] : res.data.structure.violation.face.data;
        res.data.structure.violation.speech.data = res.data.structure.violation.speech.data == null ? [] : res.data.structure.violation.speech.data;
        this.list = res.data;

      })
    }
  }
}
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  color: #575757 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.v-alert--text:before {
  background-color: #fefbd8 !important;
}
.v-alert--text {
  background: #fefbd8 !important;
}
.color-warning-cutsom {
  color: #8c7913 !important;
}
</style>