<template>
  <body style="width: 100%;">
    <!-- :style="{backgroundImage: `url(${BsG})`}" -->
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <div v-else class="pa-4 mt-4" >
      <object
        :data="`https://sertifikat-pusatbahasaunmer.epot.co.id/pdf/?id=${data.license}`"
        type="application/pdf"
        style="margin-top: 64px; width: 100%; height: 80vh"
      ></object>
    </div>
  </body>
</template>
<script>
import BG from "@/assets/images/unmer-bg.png";
// import VueQrcode from "vue-qrcode";

export default {
  name: "template-certificate",
  // components: { VueQrcode },
  props: {
    loading: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
  },
  computed: {
    BsG() {
      return BG;
    },
    correct() {
      let section = this.data.section;
      return section
        ? `${
            section.listening.total_point +
            section.reading.total_point +
            section.structure.total_point
          }`
        : "";
    },
    expired() {
      let expired = new Date(this.data.end_test);
      expired.setFullYear(expired.getFullYear() + 1);
      return this.date_format(expired);
    },
  },
  methods: {
    date_format(raw_date) {
      let date = new Date(raw_date);

      return Date.parse(raw_date)
        ? `${date.toLocaleString("default", {
            month: "long",
          })} ${date.getDate()}, ${date.getFullYear()}`
        : "";
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700");
</style>
